<template>
  <button
    @click="onCopy"
    :class="classes"
  >
    <div class="user-voucher-card__body">
      <div class="user-voucher-card__header">
        <div class="user-voucher-card__title">
          <p class="user-voucher-card__name">{{ voucher.name }}</p>

          <p class="user-voucher-card__type">{{ $t(`resto.user_voucher_type_${ voucher.type }`) }}</p>
          <p 
            class="user-voucher-card__type" 
            v-if="validityPeriodDate">
            {{ $t('resto.voucher_to_date', { date: validityPeriodDate }) }}
          </p>
        </div>

        <data-cell-voucher-status :content="voucher" />
      </div>

      <div class="user-voucher-card__perks">
        <div class="user-voucher-card__perk">
          <span
            v-if="voucher.type !== 'perks'"
            class="user-voucher-card__perk-title"
          >{{ discount }}</span>

          <template v-else>
            <div
              v-for="perk in voucher.perks"
              class="user-voucher-card__perk-wrapper"
              :key="perk.id">
              <div class="user-voucher-card__perk-title">
                <ui-icon glyph="check"/>
                <span>{{ perk.name }}</span>
              </div>

              <p class="user-voucher-card__perk-description">{{ perk.clientMessage }}</p>
            </div>
          </template>
        </div>
      </div>
    </div>

    <div class="user-voucher-card__footer">
      <div class="user-voucher-card__code">{{ voucher.code }}</div>

      <button class="user-voucher-card__copy">
        <ui-icon class="user-voucher-card__copy-icon" glyph="copy" />
      </button>
    </div>
  </button>
</template>

<script>
import DataCellVoucherStatus from '../data/table-cell-voucher-status.vue'
import MixinCurrency from '@/helpers/currency'

export default {
  name: 'UserVoucherCard',

  components: {
    DataCellVoucherStatus
  },

  mixins: [
    MixinCurrency
  ],

  props: {
    voucher: {
      type: Object,
      required: true
    }
  },

  computed: {
    classes() {
      return {
        'user-voucher-card': true
      }
    },

    discount() {
      if(this.voucher.amountType === 'number') {
        return `-${ this.toCurrency(this.voucher.amount) }`
      }

      return `-${ this.voucher.amount } %`
    },

    validityPeriodDate() {
      return this.voucher.to ? this.$basil.i18n.date(new Date(this.voucher.to)) : null
    }
  },

  methods: {
    async onCopy() {
      if(!navigator.clipboard) return

      try {
        await navigator.clipboard.writeText(this.voucher.code)

        this.$notification({
          title: this.$t('resto.copy_voucher_success'),
          type: 'success'
        })
      } catch(e) {
        $console.error(e)
        this.$notification({
          title: this.$t('resto.copy_voucher_error'),
          type: 'error'
        })
      }
    },
  }
}
</script>

<template>
  <nav class="breadcrumb">
    <ul class="breadcrumb__list">
      <li
        v-for="(child, i) in children"
        :key="i"
        class="breadcrumb__item">
        <router-link
          :to="child.href"
          class="breadcrumb__link"
        >{{ child.label }}</router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'breadcrumb',

  props: {
    children:{
      type: Array
    }
  }
}
</script>

<template>
  <div class="data-table-cell-voucher-status data-table-cell-group">
    <data-tag 
      v-bind="activityAttrs"
      v-if="label"
    >{{ label }}</data-tag>
  </div>
</template>

<script>
export default {
  name: 'DataTableCellVoucherStatus',

  props: {
    content: {
      type: Object
    },
  },

  computed: {
    activityAttrs() {
      return {
        class: {
          'data-table-cell-voucher-status__tag': true,
          [this.activityHue]: true
        },
        size: this.$pepper.Size.S
      }
    },

    activityHue() {
      let ret = null

      if(this.statuses.partiallyDepleted) {
        ret = '-orange' 
      }

      if(this.statuses.redeemed) {
        ret = '-green'
      }

      if(this.statuses.depleted) {
        ret = '-red' 
      }

      if(this.statuses.expired || this.statuses.inactive) {
        ret = '-gray'
      }
      

      if(this.statuses.active || ret == null) {
        ret = '-blue' 
      }

      return ret
    },

    label() {
      return !this.content.isTemplate ? this.$t(`resto.user_voucher_status_${this.$basil.get(this.content, 'status')}`) : null
    },

    statuses() {
      let ret = {
        active: this.$basil.get(this.content, 'status', 'unresolved') === 'active',
        expired: this.$basil.get(this.content, 'status', 'unresolved') === 'expired',
        inactive: this.$basil.get(this.content, 'status', 'unresolved') === 'inactive',
        redeemed: this.$basil.get(this.content, 'status', 'unresolved') === 'redeemed',
        partiallyDepleted: this.$basil.get(this.content, 'status', 'unresolved') === 'partially_depleted',
        depleted: this.$basil.get(this.content, 'status', 'unresolved') === 'depleted',
      }

      return ret
    },
  }
}
</script>

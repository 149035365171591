<template>
  <main class="user-vouchers">
    <div class="container">
      <div
        v-if="loading"
        class="user-vouchers__loader"
      >
        <ui-loader />
      </div>

      <data-empty
        v-else-if="vouchers.length === 0"
        :title="$t('resto.user_vouchers_empty_title')"
        :description="$t('resto.user_vouchers_empty_description')"
      />

      <template v-else>
        <header class="user__header">
          <navigations-breadcrumb :children="breadcrumbs" />
          <h1 class="user__title">{{ $t('resto.user_vouchers_title')}}</h1>
        </header>

        <div class="user__body user-vouchers__body">
          <ui-card-voucher
            v-for="voucher in vouchers"
            :key="voucher.id"
            :voucher="voucher"
          />
        </div>
      </template>
    </div>
  </main>
</template>

<script>
import NavigationsBreadcrumb from '@/components/navigations/breadcrumb'

import UiCardVoucher from '../../components/ui/card-voucher.vue'

export default {
  name: 'UserVouchers',

  components: {
    NavigationsBreadcrumb,
    UiCardVoucher,
  },

  inject: [
    '$user',
  ],

  data() {
    return {
      breadcrumbs: [
        { label: this.$t('resto.user_account_title'), href: {name: 'sayl-front-user.home'} }, //Your account',
        { label:  this.$t('resto.user_vouchers_title'), href: '#' }
      ],
      loading: false,
      vouchers: [],
    }
  },

  computed: {
    user() {
      return this.$basil.get(this.$user, 'user')
    },
  },

  methods: {
    async copyCode(code) {
      if(!navigator.clipboard) return

      try {
        await navigator.clipboard.writeText(code)

        this.$notification({
          title: this.$t('resto.copy_voucher_success'),
          type: 'success'
        })
      } catch(e) {
        $console.error(e)
        this.$notification({
          title: this.$t('resto.copy_voucher_error'),
          type: 'error'
        })
      }
    },

    async init() {
      try {
        this.loading = true
        let res = await this.$user.getVouchers({})

        this.vouchers = res
      } catch(e) {
        $console.error(e)
      } finally {
        this.loading = false
      }
    }
  },

  mounted() {
    if(!this.user || !this.user.isLogged) {
      this.$router.replace({name: 'sayl-front-user.login'}).catch(() => {})
      return
    }

    this.init()
  }
}
</script>
